import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    component: () => import('../views/login/login.vue'),
    name: 'denglu',
    meta: { title: '登陆' },
    children: []
  },
  // {
  //   path: '/wx',
  //   component: () => import('../views/operation/allTencent.vue'),
  //   name: 'denglu',
  //   meta: { title: '登陆' },
  //   children: [],
  // },
  {
    path: '/home',
    name: 'index',
    meta: { title: '首页' },
    component: () => import('../views/index/Home.vue'),
    children: [
      {
        path: '/index',
        name: 'index',
        meta: { title: '首页' },
        component: () => import('../views/index/index.vue')
      },
      {
        path: '/mobile',
        name: 'mobile',
        meta: { title: '移动端数据' },
        component: () => import('../views/index/mobile.vue')
      },
      // 平台管理
      {
        path: '/allTencent',
        name: 'allTencent',
        meta: { title: '所有公众号', keepAlive: true },
        component: () => import('../views/operation/allTencent.vue')
      },
      {
        path: '/allApplet',
        name: 'allApplet',
        meta: { title: '所有小程序' },
        component: () => import('../views/operation/allApplet.vue')
      },
      {
        path: '/AppletTencentURL',
        name: 'AppletTencentURL',
        meta: { title: '小程序公众号链接' },
        component: () => import('../views/operation/AppletTencentURL.vue')
      },
      {
        path: '/crowdPackage',
        name: 'crowdPackage',
        meta: { title: '人群包下载' },
        component: () => import('../views/operation/crowdPackage.vue')
      },
      {
        path: '/autoResponse',
        name: 'autoResponse',
        meta: { title: '自动回复' },
        component: () => import('../views/operation/function/autoResponse.vue')
      },
      {
        path: '/autoResponseDetails',
        name: 'autoResponseDetails',
        meta: { title: '自动回复详情' },
        component: () => import('../views/operation/function/autoResponseDetails.vue')
      },
      {
        path: '/keyWordResponseDetails',
        name: 'keyWordResponseDetails',
        meta: { title: '关键词回复编辑' },
        component: () => import('../views/operation/function/keyWordResponseDetails.vue')
      },
      {
        path: '/wordTencent',
        name: 'wordTencent',
        meta: { title: '我的公众号' },
        component: () => import('../views/operation/wordTencent.vue')
      },
      {
        path: '/tencentDetails',
        name: 'tencentDetails',
        meta: { title: '公众号详情菜单' },
        component: () => import('../views/operation/tencentDetails.vue'),
        children: [
          {
            path: '/tencentDetailsIndex',
            name: 'tencentDetailsIndex',
            meta: { title: '公众号详情' },
            component: () => import('../views/operation/tencentDetailsIndex.vue')
          },
          {
            path: '/greet',
            name: 'greet',
            meta: { title: '延迟消息' },
            component: () => import('../views/operation/function/greet.vue')
          },
          {
            path: '/multimediaManagement',
            name: 'multimediaManagement',
            meta: { title: '多媒体管理' },
            component: () => import('../views/operation/function/multimediaManagement.vue')
          },
          {
            path: '/newsCd',
            name: 'newsCd',
            meta: { title: '客服消息' },
            component: () => import('../views/operation/function/newsCd.vue')
          },
          {
            path: '/newsCdDetails',
            name: 'newsCdDetails',
            meta: { title: '客服消息详情' },
            component: () => import('../views/operation/function/newsCdDetails.vue')
          },
          {
            path: '/mass',
            name: 'mass',
            meta: { title: '高级群发' },
            component: () => import('../views/operation/function/mass.vue')
          },
          {
            path: '/massDetails',
            name: 'massDetails',
            meta: { title: '高级群发详情' },
            component: () => import('../views/operation/function/massDetails.vue')
          },
          {
            path: '/modelMessage',
            name: 'modelMessage',
            meta: { title: '模版消息' },
            component: () => import('../views/operation/function/modelMessage.vue')
          },
          {
            path: '/modelMessageDetails',
            name: 'modelMessageDetails',
            meta: { title: '模版消息详情' },
            component: () => import('../views/operation/function/modelMessageDetails.vue')
          },
          {
            path: '/customMenus',
            name: 'customMenus',
            meta: { title: '自定义菜单' },
            component: () => import('../views/operation/function/customMenus.vue')
          },
          {
            path: '/fansManagement',
            name: 'fansManagement',
            meta: { title: '粉丝管理' },
            component: () => import('../views/operation/fans/fansManagement.vue')
          },
          {
            path: '/unpaidModelMessage',
            name: 'unpaidModelMessage',
            meta: { title: '待充值模版消息' },
            component: () => import('../views/operation/function/unpaidModelMessage.vue')
          },
          {
            path: '/unpaidModelMessageDetails',
            name: 'unpaidModelMessageDetails',
            meta: { title: '待充值模版消息详情' },
            component: () => import('../views/operation/function/unpaidModelMessageDetails.vue')
          },
          {
            path: '/sizeModelMessage',
            name: 'sizeModelMessage',
            meta: { title: '大小模版消息' },
            component: () => import('../views/operation/function/sizeModelMessage.vue')
          },
          {
            path: '/sizeModelMessageDetails',
            name: 'sizeModelMessageDetails',
            meta: { title: '大小模版消息详情' },
            component: () => import('../views/operation/function/sizeModelMessageDetails.vue')
          },
          {
            path: '/crowPack',
            name: 'crowPack',
            meta: { title: '人群包' },
            component: () => import('../views/operation/function/crowPack.vue')
          }
        ]
      },
      {
        path: '/bookChannel',
        name: 'bookChannel',
        meta: { title: '书城渠道' },
        component: () => import('../views/operation/function/bookChannel.vue')
      },
      {
        path: '/tencentFans',
        name: 'tencentFans',
        meta: { title: '公众号数据列表' },
        component: () => import('../views/operation/tencentFans.vue')
      },
      {
        path: '/deleteYuewenApp',
        name: 'deleteYuewenApp',
        meta: { title: '删除阅文app' },
        component: () => import('../views/yuewenPlatform/deleteYuewenApp.vue')
      },
      // 平台管理 end

      // 用户管理
      {
        path: '/departmentManagement',
        name: 'departmentManagement',
        meta: { title: '部门管理' },
        component: () => import('../views/userManagement/departmentManagement.vue')
      },
      {
        path: '/menuManagement',
        name: 'menuManagement',
        meta: { title: '菜单管理' },
        component: () => import('../views/userManagement/menuManagement.vue')
      },
      {
        path: '/userManagement',
        name: 'userManagement',
        meta: { title: '用户管理' },
        component: () => import('../views/userManagement/userManagement.vue')
      },
      {
        path: '/roleManageMent',
        name: 'roleManageMent',
        meta: { title: '角色管理' },
        component: () => import('../views/userManagement/roleManageMent.vue')
      },
      // 用户管理 end

      // 推广链接

      {
        path: '/yueWenWork',
        name: 'yueWenWork',
        meta: { title: '阅文作品库' },
        component: () => import('../views/expandLink/yueWenWork.vue')
      },
      {
        path: '/yuewenGetUrl',
        name: 'yuewenGetUrl',
        meta: { title: '阅文提取链接' },
        component: () => import('../views/expandLink/yuewenGetUrl.vue')
      },
      // 推广链接 end

      // 百度、头条、ADQ、快手账户管理
      {
        path: '/baiduSuperAccount',
        name: 'baiduSuperAccount',
        meta: { title: '百度超管账户' },
        component: () => import('../views/accountManagement/baiduSuperAccount.vue')
      },
      {
        path: '/baiduSuperAccount2',
        name: 'baiduSuperAccount2',
        meta: { title: '百度超管账户2' },
        component: () => import('../views/accountManagement/baiduSuperAccount2.vue')
      },
      {
        path: '/baiduAccount',
        name: 'baiduAccount',
        meta: { title: '百度子账户' },
        component: () => import('../views/accountManagement/baiduAccount.vue')
      },
      {
        path: '/toutiaoSuperAccount',
        name: 'toutiaoSuperAccount',
        meta: { title: '头条超管账户' },
        component: () => import('../views/accountManagement/toutiaoSuperAccount.vue')
      },
      {
        path: '/toutiaoSonAccount',
        name: 'toutiaoSonAccount',
        meta: { title: '头条子账户' },
        component: () => import('../views/accountManagement/toutiaoSonAccount.vue')
      },
      {
        path: '/adqSuperAccount',
        name: 'adqSuperAccount',
        meta: { title: 'adq超管账户' },
        component: () => import('../views/accountManagement/adqSuperAccount.vue')
      },
      {
        path: '/toutiaoSuperEmail',
        name: 'toutiaoSuperEmail',
        meta: { title: '头条总邮箱' },
        component: () => import('../views/accountManagement/toutiaoSuperEmail.vue')
      },
      {
        path: '/adqSonAccount',
        name: 'adqSonAccount',
        meta: { title: 'adq子账户' },
        component: () => import('../views/accountManagement/adqSonAccount.vue')
      },
      {
        path: '/mpSuperAccount',
        name: 'mpSuperAccount',
        meta: { title: 'mp超管账户' },
        component: () => import('../views/accountManagement/mpSuperAccount.vue')
      },
      {
        path: '/mpSonAccount',
        name: 'mpSonAccount',
        meta: { title: 'mp子账户' },
        component: () => import('../views/accountManagement/mpSonAccount.vue')
      },
      {
        path: '/kuaishouSonAccount',
        name: 'kuaishouSonAccount',
        meta: { title: '快手子账户' },
        component: () => import('../views/accountManagement/kuaishouSonAccount.vue')
      },
      {
        path: '/kuaishouSuperAccount',
        name: 'kuaishouSuperAccount',
        meta: { title: '快手超管户' },
        component: () => import('../views/accountManagement/kuaishouSuperAccount.vue')
      },
      // 百度、头条、ADQ账户管理 end

      // 百度、头条、ADQ配置
      {
        path: '/baiduAccountConfig',
        name: 'baiduAccountConfig',
        meta: { title: '百度账户配置' },
        component: () => import('../views/config/baiduAccountConfig.vue')
      },
      {
        path: '/adqAccountConfig',
        name: 'adqAccountConfig',
        meta: { title: 'adq账户配置' },
        component: () => import('../views/config/adqAccountConfig.vue')
      },
      {
        path: '/toutiaoAccountConfig',
        name: 'toutiaoAccountConfig',
        meta: { title: '头条账户配置' },
        component: () => import('../views/config/toutiaoAccountConfig.vue')
      },
      {
        path: '/mpAccountConfig',
        name: 'mpAccountConfig',
        meta: { title: 'mp账户配置' },
        component: () => import('../views/config/mpAccountConfig.vue')
      },
      {
        path: '/dataTable',
        name: 'dataTable',
        meta: { title: '数据表' },
        component: () => import('../views/dataSystem/dataTable.vue')
      },
      // 百度、头条、ADQ配置 end


      // 百度、头条、ADQ实时监控
      {
        path: '/baiduLaunchedData',
        name: 'baiduLaunchedData',
        meta: { title: '百度广告主数据' },
        component: () => import('../views/launchedData/baiduLaunchedData.vue')
      },
      {
        path: '/adqLaunchedData',
        name: 'adqLaunchedData',
        meta: { title: 'adq广告主数据' },
        component: () => import('../views/launchedData/adqLaunchedData.vue')
      },
      {
        path: '/toutiaoLaunchedData',
        name: 'toutiaoLaunchedData',
        meta: { title: '头条广告主数据' },
        component: () => import('../views/launchedData/toutiaoLaunchedData.vue')
      },
      {
        path: '/mpLaunchedData',
        name: 'mpLaunchedData',
        meta: { title: 'mp广告主数据' },
        component: () => import('../views/launchedData/mpLaunchedData.vue')
      },
      {
        path: '/fastappToutiaoLaunchedData',
        name: 'fastappToutiaoLaunchedData',
        meta: { title: '快应用头条广告主数据' },
        component: () => import('../views/launchedData/fastappToutiaoLaunchedData.vue')
      },

      // 百度、头条、ADQ实时监控 end

      // 百度、头条、ADQ素材管理
      {
        path: '/baiduMaterial',
        name: 'baiduMaterial',
        meta: { title: '百度素材' },
        component: () => import('../views/materialManagement/baiduMaterial.vue')
      },
      {
        path: '/toutiaoMaterial',
        name: 'toutiaoMaterial',
        meta: { title: '头条素材' },
        component: () => import('../views/materialManagement/toutiaoMaterial.vue')
      },
      {
        path: '/adqMaterial',
        name: 'adqMaterial',
        meta: { title: 'adq素材' },
        component: () => import('../views/materialManagement/adqMaterial.vue')
      },
      {
        path: '/mpMaterial',
        name: 'mpMaterial',
        meta: { title: 'mp素材' },
        component: () => import('../views/materialManagement/mpMaterial.vue')
      },
      // 百度、头条、ADQ实时监控 end

      // 日志管理
      {
        path: '/operlogs',
        name: 'operlogs',
        meta: { title: '操作日志' },
        component: () => import('../views/logManagement/operlogs.vue')
      },
      // 日志管理 end

      // 数据源管理
      {
        path: '/duridinfo',
        name: 'duridinfo',
        meta: { title: '操作日志' },
        component: () => import('../views/dataManagement/duridinfo.vue')
      },
      // 数据源管理 end


      // 数据系统
      {
        path: '/indexData',
        name: 'indexData',
        meta: { title: '数据系统' },
        component: () => import('../views/dataSystem/indexData.vue')
      },
      {
        path: '/testBook',
        name: 'testBook',
        meta: { title: '测试书' },
        component: () => import('../views/dataSystem/testBook.vue')
      },
      {
        path: '/fantasy',
        name: 'fantasy',
        meta: { title: '玄幻书' },
        component: () => import('../views/dataSystem/fantasy.vue')
      },
      {
        path: '/echoData',
        name: 'echoData',
        meta: { title: '回本数据' },
        component: () => import('../views/dataSystem/echoData.vue')
      },
      {
        path: '/royalty',
        name: 'royalty',
        meta: { title: '小说提成' },
        component: () => import('../views/dataSystem/royalty.vue')
      },
      {
        path: '/viadoroyalty',
        name: 'viadoroyalty',
        meta: { title: '短剧提成' },
        component: () => import('../views/dataSystem/viadoroyalty.vue')
      },
      {
        path: '/selectResponseData',
        name: 'selectResponseData',
        meta: { title: '回传' },
        component: () => import('../views/dataSystem/selectResponseData.vue')
      },
      {
        path: '/selectOrder',
        name: 'selectOrder',
        meta: { title: '查询订单' },
        component: () => import('../views/dataSystem/selectOrder.vue')
      },
      {
        path: '/notDyeingTime',
        name: 'notDyeingTime',
        meta: { title: '缺失染色' },
        component: () => import('../views/dataSystem/notDyeingTime.vue')
      },
      {
        path: '/dataConfig',
        name: 'dataConfig',
        meta: { title: '模版配置' },
        component: () => import('../views/dataSystem/dataConfig.vue')
      },
      {
        path: '/zhongWenAccount',
        name: 'zhongWenAccount',
        meta: { title: '中文账户' },
        component: () => import('../views/dataSystem/zhongWenAccount.vue')
      },
      {
        path: '/fianceView',
        name: 'fianceView',
        meta: { title: '财务订单' },
        component: () => import('../views/dataSystem/fianceView.vue')
      }, 
      {
        path: '/sjUserPage',
        name: 'sjUserPage',
        meta: { title: '数据组' },
        component: () => import('../views/dataSystem/sjUserPage.vue')
      }, 
      {
        path: '/homePageRecharge',
        name: 'homePageRecharge',
        meta: { title: '首页' },
        component: () => import('../views/dataSystem/homePageRecharge.vue')
      },
      // 数据系统 end

      // 企业微信
      {
        path: '/qiyeAccount',
        name: 'qiyeAccount',
        meta: { title: '企业微信账户' },
        component: () => import('../views/WeCom/qiyeAccount.vue')
      },
      {
        path: '/qiyeCustomer',
        name: 'qiyeCustomer',
        meta: { title: '企业微信客服' },
        component: () => import('../views/WeCom/qiyeCustomer.vue')
      },
      {
        path: '/wxAccountConfig',
        name: 'wxAccountConfig',
        meta: { title: '公众号数据源配置' },
        component: () => import('../views/WeCom/wxAccountConfig.vue')
      },
      // 企业微信 end


      // 腾讯ADQ
      {
        path: '/adqbatch',
        name: 'adqbatch',
        meta: { title: '批量定制' },
        component: () => import('../views/batchManagement/ADQ/adqbatch.vue')
      },
      {
        path: '/targetAdd',
        name: 'targetAdd',
        meta: { title: 'ADQ定向包' },
        component: () => import('../views/batchManagement/ADQ/targetAdd.vue')
      },

      // 腾讯ADQ end

      // 腾讯MP 
      {
        path: '/MPtargetAdd',
        name: 'MPtargetAdd',
        meta: { title: 'MP定向包' },
        component: () => import('../views/batchManagement/MP/MPtargetAdd.vue')
      },
      
      // 腾讯MP end

      // 阅文平台
      {
        path: '/consumer',
        name: 'consumer',
        meta: { title: '客服消息' },
        component: () => import('../views/yuewenPlatform/consumer.vue')
      },
      {
        path: '/batchSenior',
        name: 'batchSenior',
        meta: { title: '阅文高级群发' },
        component: () => import('../views/yuewenPlatform/batchSenior.vue')
      },
      // 阅文平台  end

      // 网易平台
      {
        path: '/bookInfo',
        name: 'bookInfo',
        meta: { title: '书籍信息' },
        component: () => import('../views/wangyiPlatform/bookInfo.vue')
      },
      {
        path: '/wangyiconsumer',
        name: 'wangyiconsumer',
        meta: { title: '网易高级群发' },
        component: () => import('../views/wangyiPlatform/wangyiconsumer.vue')
      },
      // 网易平台  end

      // 万读
      {
        path: '/wandubatchSenior',
        name: 'wandubatchSenior',
        meta: { title: '万读高级群发' },
        component: () => import('../views/wandu/wandubatchSenior.vue')
      },

      // 万读  end

      // 花生
      {
        path: '/huashenBatchSenior',
        name: 'huashenBatchSenior',
        meta: { title: '花生高级群发' },
        component: () => import('../views/Platform/huashenBatchSenior.vue')
      },

      // 花生  end

      // 阳光
      {
        path: '/yangguangBatchSenior',
        name: 'yangguangBatchSenior',
        meta: { title: '阳光高级群发' },
        component: () => import('../views/Platform/yangguangbatchSenior.vue')
      },

      // 阳光  end

      // 快应用
      {
        path: '/faIndexData',
        name: 'faIndexData',
        meta: { title: '快应用回本数据' },
        component: () => import('../views/fastApp/faIndexData.vue')
      },
      {
        path: '/fafantasy',
        name: 'fafantasy',
        meta: { title: '玄幻书' },
        component: () => import('../views/fastApp/fafantasy.vue')
      },
      {
        path: '/faechoData',
        name: 'faechoData',
        meta: { title: '书的回本' },
        component: () => import('../views/fastApp/faechoData.vue')
      },
      {
        path: '/faroyalty',
        name: 'faroyalty',
        meta: { title: '快应用提成' },
        component: () => import('../views/fastApp/faroyalty.vue')
      },
      // 快应用  end

      // 运营
      {
        path: '/wpGroupByUser',
        name: 'wpGroupByUser',
        meta: { title: '运营公众号分配' },
        component: () => import('../views/operation/operator/wpGroupByUser.vue')
      },
      // 运营  end


      // 头条短剧批量
      {
        path: '/toutiaoProvision',
        name: 'toutiaoProvision',
        meta: { title: '备款户' },
        component: () => import('../views/videoBatchManagement/toutiaoVideo/toutiaoProvision.vue')
      },
      {
        path: '/titleGroup',
        name: 'titleGroup',
        meta: { title: '标题组' },
        component: () => import('../views/videoBatchManagement/toutiaoVideo/titleGroup.vue')
      },
      {
        path: '/Directional',
        name: 'Directional',
        meta: { title: '定向包' },
        component: () => import('../views/videoBatchManagement/toutiaoVideo/Directional.vue')
      },
      {
        path: '/toutiaoAddModel',
        name: 'toutiaoAddModel',
        meta: { title: '头条新增模板' },
        component: () => import('../views/videoBatchManagement/toutiaoVideo/toutiaoAddModel.vue')
      },
      {
        path: '/toutiaoAddModel2',
        name: 'toutiaoAddModel2',
        meta: { title: '头条新增模板2.0' },
        component: () => import('../views/videoBatchManagement/toutiaoVideo/toutiaoAddModel2.vue')
      },
      {
        path: '/toutiaoSetModel2',
        name: 'toutiaoSetModel2',
        meta: { title: '头条编辑模板2.0' },
        component: () => import('../views/videoBatchManagement/toutiaoVideo/toutiaoSetModel2.vue')
      },
      {
        path: '/toutiaoModelInfo',
        name: 'toutiaoModelInfo',
        meta: { title: '头条推广创建信息' },
        component: () => import('../views/videoBatchManagement/toutiaoVideo/toutiaoModelInfo.vue')
      },
      // 头条短剧批量 end

      // MP短剧批量 
      {
        path: '/MPconversion',
        name: 'MPconversion',
        meta: { title: 'MP转化归因' },
        component: () => import('../views/videoBatchManagement/MPvideo/MPconversion.vue')
      },
      {
        path: '/MPtitleGroup',
        name: 'MPtitleGroup',
        meta: { title: 'MP标题组' },
        component: () => import('../views/videoBatchManagement/MPvideo/MPtitleGroup.vue')
      },
      
      {
        path: '/mpAddModel',
        name: 'mpAddModel',
        meta: { title: 'MP批量创建' },
        component: () => import('../views/videoBatchManagement/MPvideo/mpAddModel.vue')
      },
      {
        path: '/mpAddModelV3',
        name: 'mpAddModelV3',
        meta: { title: '批量腾讯V3' },
        component: () => import('../views/videoBatchManagement/MPvideo/mpAddModelV3.vue')
      },
      {
        path: '/mpModelInfo',
        name: 'mpModelInfo',
        meta: { title: 'mp创建返回信息' },
        component: () => import('../views/videoBatchManagement/MPvideo/mpModelInfo.vue')
      },
      // MP短剧批量 end

      // 素材
      {
        path: '/videoSucai',
        name: 'videoSucai',
        meta: { title: '视频素材' },
        component: () => import('../views/sucai/videoSucai.vue')
      },

      // 素材 end

            // 分销商数据分析
            {
              path: '/qishuRecharge',
              name: 'qishuRecharge',
              meta: { title: '视频素材' },
              component: () => import('../views/distributor/qishuRecharge.vue')
            }
            // 分销商数据分析 end


    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // JSON.parse(localStorage.getItem('menus')).forEach(item => {
  //   if(item)
  // });
  // 根据不同页面换不同的meta
  const oMeta = document.createElement('meta');
  // 为了能打开添加公众号接口

  if (to.path == '/allTencent') {
    oMeta.name = 'referrer'
    oMeta.content = 'origin-when-cross-origin'
    document.getElementsByTagName('head')[0].appendChild(oMeta);
  } else {
    // 解决微信图片无法显示问题
    oMeta.name = 'referrer'
    oMeta.content = 'never'
    document.getElementsByTagName('head')[0].appendChild(oMeta);
  }
  next()
})

export default router
