import { createApp } from 'vue'

// 引入自动更新提醒
import autoUpdate from './auto-update'
// 非生产环境使用
process.env.VUE_APP_ENV !== 'production' && autoUpdate.start()
 

import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'dayjs/locale/zh-cn'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import 'element-plus/lib/theme-chalk/index.css'
createApp(App)
  .use(store)
  .use(router)
  .use(ElementPlus, { locale })
  .mount('#app')



//   import { ref, reactive, toRefs, watch } from 'vue'
// import router from '@/router/index.js'
// import { onBeforeRouteUpdate } from 'vue-router'F
// 封装组件
// 子组件
// attrs展开接收过来 不需要处理的数据
// <input type="text" v-bind="$attrs" />
// 在子组件接受参数
// props:['items','']
// 在子组件抛出方法
// emits:['update:modelValue']
// ref ref.value.name 可以让对象双向绑定
// reactive 能存放数组 并双向绑定
// toRefs 可以解构...toRefs(user)

// window.addEventListener('load', () => {
//   // 强制刷新页面
//   if (window.location.protocol !== 'https:' && window.location.host !== 'localhost') {
//     window.location.reload(true);
//   }
// });