<template>
  <el-config-provider :locale="locale">
    <div id="nav">
      <router-link to="/"></router-link>
      <router-view></router-view>
    </div>
  </el-config-provider>
</template>
<script>
import {
  reactive,
  toRefs,
  defineComponent,
  onMounted,
  onUnmounted,
  h,
} from 'vue'

import { ElConfigProvider } from 'element-plus'
import CnNotify from '@/components/others/CnNotify.vue'
import { getmark } from './store/watermark'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
export default defineComponent({
  name: 'app',
  components: {
    ElConfigProvider,
    CnNotify,
  },
  setup() {
    const state = reactive({})
    const { watermark } = getmark()

    function timestampToTime(times) {
      const time = times[1]
      let mdy = times[0]

      mdy = mdy.split('/')
      const month = parseInt(mdy[0])
      const day = parseInt(mdy[1])
      const year = parseInt(mdy[2])

      return year + '-' + month + '-' + day + ' ' + time
    }
    const time = new Date()
    const nowTime = timestampToTime(
      time.toLocaleString('en-US', { hour12: false }).split(' ')
    )

    onMounted(() => {
      if (`${localStorage.getItem('userName')}` == '测试账号') {
        watermark(`${'null'}   ${nowTime}`)
      } else {
        watermark(`${localStorage.getItem('userName')}   ${nowTime}`)
      }
    })

    onUnmounted(() => {
      watermark('')
    })

    return {
      ...toRefs(state),
      locale: zhCn,
    }
  },

  created() {},

  mounted() {
    window.addEventListener(
      'hashchange',
      (e) => {
        const currentPath = window.location.hash.slice(1) // 获取输入的路由

        if (this.$router.path !== currentPath) {
          this.$$route.push(currentPath) // 动态跳转
        }
      },
      false
    )
    this.watchUpdate()
  },

  methods: {
    watchUpdate() {
      window.addEventListener('onmessageUpdate', (res) => {
        let msg = res.detail.msg,
          version = res.detail.version
        this.$notify({
          title: '版本更新提示',
          duration: 0,
          position: 'bottom-right',
          dangerouslyUseHTMLString: true,
          message: h('CnNotify', {
            // 使用自定义组件
            ref: 'CnNotify',
            props: {
              msg: msg,
              version: version,
            },
          }),
          customClass: 'versionNotifyStyle', //自定义类名
        })
      })
    },
  },
})
</script>
<style src="./store/index.css"></style>
